<template>
  <div style="" class="font-family" data-app>
    <!-- <div class="nav-bar">
      <div>
      <img src="/media/svg/moon-assets/header_logo.png" />
  
      </div>

      <b-navbar toggleable="lg" type="dark" variant="info">
        <span
          :style="isMobile ? 'margin-right:0px' : 'margin-right:0px'"
        ></span>
        <img
          style="height: 30px; width: 50px"
          src="/media/svg/moon-assets/moon-new-logo.png"
        />


        <div :hidden="isMobile ? true : false" class="nav-calender">
          <b-navbar-brand href="#" style="font-size: 16px">
            <i
              aria-hidden="true"
              class="v-icon mdi mdi-calendar theme--dark"
            ></i>
            25-10-2021
            <b-icon-arrow-right></b-icon-arrow-right> 25-10-2021
            

            <v-menu
              ref="menu"
              :content-class="isMobile ? '' : 'cal-dropdown'"
              v-model="menu"
              id="test"
              rounded="large"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datesHeader"
                  readonly
                  :placeholder="
                    dates.length == 2 ? dates.join(' → ') : 'Select Date Range'
                  "
                  v-bind="attrs"
                  v-on="on"
                >
                  <template v-slot:prepend>
                    <img
                      src="/media/svg/moon-assets/calender.svg"
                      style="
                        height: 13px;
                        width: 13px;
                        margin-right: 8px;
                        margin-top: 4px;
                      "
                    />
                  </template>
                </v-text-field>
              </template>

              <v-flex class="date-picker-card">
                <v-card class="py-3">
                  <v-layout :wrap="'true'">
                    <v-flex>
                      <v-date-picker
                        id="pickerad1"
                        ref="pickerad1"
                        :events="events"
                        :event-color="'skyblue'"
                        v-model="dates"
                        :picker-date.sync="pickerPage1"
                        :disabled="noDateSpecified"
                        range
                        multiple
                        no-title
                        style="height: 240px"
                        :input-props="{ class: 'data-picker' }"
                      ></v-date-picker>
                    </v-flex>
                    <v-spacer></v-spacer>
                  </v-layout>
                  <v-card-actions
                    style="
                      display: flex;
                      justify-content: flex-end;
                      color: #fff;
                    "
                  >
                    <v-btn
                      text
                      color="primary"
                      @click="
                        emptyFilterData();
                        $refs.menu.save(dates);
                        apply_filter();
                      "
                    >
                      Clear
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.menu.save(dates);
                        apply_filter();
                      "
                    >
                      OK
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-menu>
          </b-navbar-brand>
        </div>
        <b-navbar-nav class="ml-auto">
          <span style="padding-left: 5px">
            <span v-if="isMobile" role="button" @click="openCalender">
              <img
                src="/media/svg/moon-assets/calender.svg"
                :style="
                  !isMobile
                    ? 'height: 35px; width: 35px'
                    : 'height: 20px;width: 20px;margin-right: 10px;'
                "
              />
            </span>
            <button
              v-if="!isMobile"
              class="logout-btn mr-2"
              variant="light"
              size="lg"
              v-b-modal.report-modal
            >
              Report a Problem
            </button>
            <span role="button" @click="$bvModal.show('filter_modal')">
              <img
                src="/media/svg/moon-assets/Path.png"
                :style="
                  !isMobile
                    ? 'height: 30px; width: 30px'
                    : 'height: 20px;width: 20px;margin-right: 5px;'
                "
              />
            </span>
            <b-button
              @click="logout"
              class="logout-btn"
              :style="isMobile ? 'height: 20px !important;' : ''"
              variant="light"
              size="lg"
            >
              Logout
            </b-button>
          </span>
          <span class="mt-2">
            <button
              v-if="isMobile"
              class="logout-btn btn-sm"
              variant="light"
              :style="isMobile ? 'height: 20px !important;width:111px;' : ''"
              v-b-modal.report-modal
            >
              Report a Problem
            </button>
          </span>
        </b-navbar-nav>
      </b-navbar>
    </div> -->
    <b-container fluid>
      <b-row>
        <b-col class="side-nav content col-md-2 col-12 d-flex flex-column align-items-center"
          style="text-align: center !important">
          <b-row class=" pt-5">
            <span v-if="isMobile" class="ml-7 mt-3 mb-4" style="color: #2e7483">Data is subject to change and these are
              unverified orders.
            </span>
            <!-- 
            <b-col cols="3">
              <img
                src="/media/svg/moon-assets/user.png"
                class="group-regular"
              />
            </b-col> -->
            <b-col cols="12">

              <img class="" src="../../../../public/media/svg/moon-assets/Logo.svg" />
            </b-col>
            <!-- <b-col cols="9">
              <h3
                style="
                  text-align: left;
                  font: normal normal normal 24px/24px Raleway;
                "
              >
                Hello,
              </h3>
              <h3
                style="
                  font: normal normal bold 30px/24px Raleway;
                  text-align: left;
                "
              >
                {{ username }}
              </h3>
              <span class="last_seen">
                <span style="color: #7f8fa4"
                  >Last update {{ last_refreshed_diff(last_refreshed) }}
                </span>
                <span>
                  <button
                    type="button"
                    class="refresh-btn"
                    @click="refreshed_data"
                  >
                    <b-icon icon="arrow-clockwise" />
                  </button>
                </span>
              </span>
            </b-col> -->
          </b-row>
          <div v-if="isMobile" class="mt-3">
            <b-collapse v-model="visible" id="collapse-2" style="height: 400px">
              <MobileSideNav />
            </b-collapse>
            <!-- <b-button style="width: 10rem; background-color: #0F0090; color: white" v-b-toggle.collapse-2 class="m-1">{{
              visible ? 'Collapse' : 'Expand' }}
              <v-icon v-if="visible">mdi-chevron-up</v-icon>
              <v-icon v-if="!visible">mdi-chevron-down</v-icon>
            </b-button> -->
          </div>

          <MobileSideNav v-if="!isMobile" />
          <div class="mt-auto p-3" style="width:100%;">
            <div @click="logout" style="color: #0F0090;float: left; font-size: :14px;">
              <img class="" src="../../../../public/media/svg/moon-assets/logout.svg" />
              <span class="pl-2"> Logout</span>
            </div>
          </div>
        </b-col>

        <b-col class="top col-md-10 col-12">

          <b-container fluid>
            <div class="row flex-column-reverse flex-lg-row flex-sm-row flex-md-row   pt-4">
              <div class="col-12 col-md-6 col-lg-6">
                <div style="font-size: 20px;color:#0F0090;font-weight: 500;">Hi {{ username }},</div>
                <div style="font-size: 35px;color:#0F0090;font-weight: 600;">Welcome back 👋</div>
              </div>
              <div class="d-flex col-12 col-md-6 col-lg-6 justify-content-end">
                <div class="searchDiv">
                  <img class="searchIcon" src="../../../../public/media/svg/moon-assets/Search.svg" />
                  <!-- <input class="searchBar" placeholder="search"/> -->
                  <input type="text" class="searchTerm" placeholder="search">
                </div>
                <div class="position-relative">
                  <!-- <div class="BellDiv ml-20">
                    <img class="" src="../../../../public/media/svg/moon-assets/bell.svg" />
                  </div>
                  <div class="BellCount">
                    2
                  </div> -->
                </div>
              </div>
            </div>
          </b-container>
          <b-container fluid>
            <div class="pt-15 d-flex justify-content-between">
              <div>
                <h3><b style="color:#0F0090">Summary</b></h3>
                <h7><span style="color:#9C9C9C">Showing for: <span>
                      <img class="" src="../../../../public/media/svg/moon-assets/akar-icons_calendar.svg" />
                    </span>
                    <span style="color:#827AC5">
                      
                  {{ moment(dates[0]).format('MMMM Do YYYY') }} - {{  moment(dates[1]).format('MMMM Do YYYY ') }}
                      <!-- 12 January - 17 January 2021 -->
                    </span>
                  </span>
                </h7>
              </div>
              
          <div class="">
                <!-- <flat-pickr
        v-model="selectedDates"
            :config="{
              mode:'range',
              open: true,
            }"
            @input="dateRangeSelected"
          ></flat-pickr> -->
    </div>
              <div class="cursor-pointer" @click="$bvModal.show('filter_modal')">
                <img class="" src="../../../../public/media/svg/moon-assets/filter.svg" /><span class="pl-2"
                  style="color:#0F0090;">Filters</span>
              </div>
            </div>

            <b-row class="mb-5 mt-11">
              <b-card class="col-12 col-md-3 plan-boxes">
                <span class="plan-box">
                  <div style="width:100%">
                    <div class="d-flex justify-content-between">
                      <div style="background-color: #FBE2F5;" class="cardIcon">
                        <img class="" src="../../../../public/media/svg/moon-assets/Vector.svg" />
                      </div>
                      <div style="padding:13px;display: none;">
                        <div class="d-flex">
                          <img class="" src="../../../../public/media/svg/moon-assets/small-up.svg" />
                          <span class="ml-3" style="color:#0F0090;font-weight: bolder;"> 23%</span>
                        </div>
                        <div class="week">
                          This week
                        </div>
                      </div>
                    </div>
                    <div class="pt-3" style="text-align: start;">
                      <div style="color:#9C9C9C;font-size:16px">
                        Total Conversions
                      </div>
                      <h1 class="m-0" style="color:#0F0090;font-weight: bolder;">
                        {{ widgets.total_conversion }}
                      </h1>
                    </div>
                  </div>
                </span>
              </b-card>
              <b-card class="col-12 col-md-3 plan-boxes">
                <span class="plan-box">
                  <div style="width:100%">
                    <div class="d-flex justify-content-between">
                      <div style="background-color: #DBD9EE;" class="cardIcon">
                        <img class="" src="../../../../public/media/svg/moon-assets/money-recive.svg" />
                      </div>
                      <div style="padding:13px;display: none;">
                        <div class="d-flex">
                          <img class="" src="../../../../public/media/svg/moon-assets/small-up.svg" />
                          <span class="ml-3" style="color:#0F0090;font-weight: bolder;"> 23%</span>
                        </div>
                        <div class="week">
                          This week
                        </div>
                      </div>
                    </div>
                    <div class="pt-3" style="text-align: start;">
                      <div style="color:#9C9C9C;font-size:16px">
                        Total Payout
                      </div>
                      <div class="d-flex">
                      <h1 class="m-0" style="color:#0F0090;font-weight: bolder;">
                        {{ widgets.total_payout }}
                      </h1>
                      <span class="d-flex align-items-end pl-2" style="color:#9C9C9C;font-size:17px;">
                        {{ widgets.total_payout_currency }}
                      </span>
                    </div>
                  </div>
                  </div>
                </span>
              </b-card>

              <b-card class="col-12 col-md-3 plan-boxes">
                <span class="plan-box">
                  <div style="width:100%">

                    <div class="d-flex justify-content-between">
                      <div style="background-color: #FFF5DB;" class="cardIcon">
                        <img class="" src="../../../../public/media/svg/moon-assets/people.svg" />
                      </div>
                      <div style="padding:13px;display: none;">
                        <div class="d-flex">
                          <img class="" src="../../../../public/media/svg/moon-assets/small-up.svg" />
                          <span class="ml-3" style="color:#0F0090;font-weight: bolder;"> 23%</span>
                        </div>
                        <div class="week">
                          This week
                        </div>
                      </div>
                    </div>
                    <div class="pt-3" style="text-align: start;">
                      <div style="color:#9C9C9C;font-size:16px">
                        Total Advertisers
                      </div>
                      <h1 class="m-0" style="color:#0F0090;font-weight: bolder;">
                      {{ widgets.total_advertiser }}
                      </h1>
                    </div>
                  </div>
                </span>
              </b-card>
              <!-- <b-card class="col-12 col-md-3 plan-boxes">
                <span class="plan-box">
                  <div style="width:100%">

                    <div class="d-flex justify-content-between">
                      <div style="background-color: #FBE2F5;" class="cardIcon">
                        <img class="" src="../../../../public/media/svg/moon-assets/additem.svg" />
                      </div>
                      <div style="padding:13px;display: none;">
                        <div class="d-flex">
                          <img class="" src="../../../../public/media/svg/moon-assets/small-up.svg" />
                          <span class="ml-3" style="color:#0F0090;font-weight: bolder;"> 23%</span>
                        </div>
                        <div class="week">
                          This week
                        </div>
                      </div>
                    </div>
                    <div class="pt-3" style="text-align: start;">
                      <div style="color:#9C9C9C;font-size:16px">
                        New Advertisers
                      </div>
                      <h1 class="m-0" style="color:#0F0090;font-weight: bolder;">
                      {{ widgets.new_advertiser }}
                      </h1>
                    </div>
                  </div>
                </span>
              </b-card> -->
            </b-row>
            <b-card>
              <b-row class="pb-10" style="color:#0F0090">
                <b-col cols="10" class="table-head">
                  <h5><b style="color: #0F0090">Performance Overview</b></h5>
                </b-col>
                <!-- <b-col class="cols-4" ></b-col> -->
                <!-- <b-col style="text-align: right" cols="2">
                <span
                  style="cursor: pointer"
                  role="button"
                  @click="toggletype = toggletype == 'table' ? 'grid' : 'table'"
                  v-if="!isMobile"
                >
                  <img
                    class="pr-2 h1"
                    v-if="toggletype == 'grid'"
                    src="/media/svg/moon-assets/grid.svg"
                  />
                  <img
                    class="pr-2 h1"
                    v-if="toggletype == 'table'"
                    src="/media/svg/moon-assets/table.svg"
                  />
                </span>

                <span>
                  <img
                    class="h1"
                    @click="
                      selected_tab == 'summary'
                        ? create_csv(sum_arr, sum_fields)
                        : create_csv(order_arr, order_columns)
                    "
                    style="cursor: pointer"
                    src="/media/svg/moon-assets/download.svg"
                    alt=""
                  />
                </span>
              </b-col> -->
                <b-col style="text-align: right" cols="2">
                  <div class="float-right" style="width:25px;height: 25px;" @click="active = !active">
                    <img class="" src="../../../../public/media/svg/moon-assets/dots.svg" />
                  </div>
                  <div v-if="active" class="gridOptions">
                    <div @click="toggletype = toggletype == 'table' ? 'grid' : 'table'" v-if="!isMobile"
                      class="gridOption">
                      <img class="" src="../../../../public/media/svg/moon-assets/carbon_view-filled.svg" />
                      <span class="pl-2">View</span>
                    </div>
                    <div class="gridOption" @click="
                      selected_tab == 'summary'
                        ? create_csv(sum_arr, sum_fields)
                        : create_csv(order_arr, order_columns)
                      ">
                      <img class="" src="../../../../public/media/svg/moon-assets/bx_bx-export.svg" />
                      <span class="pl-2">Export</span>
                    </div>
                    <div class="gridOption">
                      <img class="" src="../../../../public/media/svg/moon-assets/feather_trash-2.svg" />
                      <span class="pl-2" style="color:#F7C2EA">Remove</span>
                    </div>
                  </div>
                </b-col>
              </b-row>

              <CardContent v-if="toggletype == 'grid' || isMobile" :allClients="sum_arr" />

              <div v-if="toggletype == 'table' && !isMobile" class="summary-table">
                <!-- <div>
                  <span class="table-head">Performance Overview</span>
                  <span
                    class="export_file"
                    @click="
                      selected_tab == 'summary'
                        ? create_csv(sum_arr, sum_fields)
                        : create_csv(order_arr, order_columns)
                    "
                  >
                    <b-img
                      height="23px"
                      width="23px"
                      src="/media/svg/moon-assets/csv-file.png"
                    />
                  </span>
                  <hr />
                </div> -->
                <div class="data_grid" v-if="selected_tab == 'summary'">
                  <div v-if="sum_arr.length > 0">
                    <b-table class="gridTable" hover :items="sum_arr" :fields="sum_fields">
                      <template v-slot:cell(conversions)="slot" style="padding: 10px; margin: 10px">
                        {{
                          slot.item.conversions.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })
                        }}
                      </template>
                      <template v-slot:cell(client_name)="slot">
                        <b>{{ slot.item.client_name }}</b>
                      </template>

                      <template v-slot:cell(payout)="slot">
                        {{ slot.item.payout_currency }}
                        {{ formatCurrency(slot.item.payout) }}
                      </template>
                      <template v-slot:cell(revenue)="slot">
                        {{ slot.item.revenue_currency }}
                        {{ formatCurrency(slot.item.revenue) }}
                      </template>
                    </b-table>
                  </div>
                  <div v-else class="d-flex mt-3 justify-content-center alert">
                    <span v-if="loading">
                      <b>Loading Data.. <b-spinner small /> </b></span>
                    <span v-if="!loading && !sum_arr.length"><b> No Record. </b></span>
                  </div>
                </div>
                <div class="data_grid" v-if="selected_tab == 'order'">
                  <div v-if="order_arr.length > 0">
                    <datatable :columns="order_columns" :data="order_arr" class="table vue_data_grid">
                      <template slot-scope="{ row }">
                        <tr class="text-center">
                          <td style="width: 20%">{{ row.client_name }}</td>
                          <td style="width: 20%">{{ row.coupon }}</td>
                          <td style="width: 20%">
                            {{ row.order_date }}
                          </td>
                          <td style="width: 20%">{{ row.payout }}</td>
                          <td style="width: 20%">{{ row.revenue }}</td>
                        </tr>
                      </template>
                    </datatable>
                  </div>
                  <div v-else class="d-flex mt-3 justify-content-center alert">
                    <b>No Records</b>
                  </div>
                </div>
              </div>
            </b-card>

            <b-row> </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>
    <!-- <div v-if="!isMobile" class="footer">
      <p>
        Data is subject to change and these are unverified orders.
        <span style="font-size: 8px">Powered by itcan.co</span>
      </p>
    </div> -->

    <b-modal id="report-modal" centered>
      <template #modal-header="{}">
        <b-row style="width: 100%">
          <b-col cols="11">
            <h6>Report A Problem</h6>
          </b-col>
          <b-col cols="1" class="text-right">
            <span role="button" @click="$bvModal.hide('report-modal')" style="color: #f35162; padding-left: 11px">
              Close
            </span>
          </b-col>
        </b-row>
      </template>

      <template #default="{}">
        <div class="row">
          <div class="col-12">Your message</div>
          <div class="col-12 d-flex">
            <b-textarea placeholder="Your Message Goes Here." v-model="reportMessage" rows="10" />
          </div>
        </div>
      </template>

      <template #modal-footer="{}">
        <b-button @click="sendReport" class="modal-btn" variant="dark">
          SEND
        </b-button>
      </template>
    </b-modal>

    <b-modal id="filter_modal" centered  :modal-class="show ? 'animated slideInRight' : 'animated slideOutRight'" @show="shown"
      @hidden="hidden" :no-fade="true"  @ok="apply_filter">
      <template #modal-header="{}">
        <div style="width:100%" v-if="modal_content == 'main'" class="d-flex justify-content-between">
          <div class="closeIcon cursor-pointer"  @click="loading=true;show = false;$bvModal.hide('filter_modal');loading=false;">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </div>
          <h5 style="color:#0F0090;margin:0">Filters</h5>
          <div class="cursor-pointer" @click="clear_filter()" style="color:#9C9C9C">Reset</div>
        </div>
        <div style="width:100%"  class="d-flex justify-content-between" v-else>
          <div>
            <img @click="modal_content='main'" class="" src="../../../../public/media/svg/moon-assets/arrow-left.svg" />
          </div>
            <h5 style="color:#0F0090;margin:0">
             {{ modal_content == 'recommended' ? 'Sort by' : 'Date' }}
             </h5>
          <div>
          </div>
        </div>
      </template>

      <template #default="{}">
        <div v-if="modal_content == 'main'" class="row">
          <!-- <div class="mt-3 col-12"> -->
            <!-- <label style="color:#9C9C9C;font-size: 12px;margin:0px" for="">Sort by</label>
            <div @click="modal_content='recommended'" class="d-flex justify-content-between cursor-pointer" style="color:#0F0090;width:98%;font-weight: 600;font-size: 14px;">
              <div>
                Recommended
              </div>
              <div>
                 <img class="" src="../../../../public/media/svg/moon-assets/keyboard_arrow_right.svg" />
              </div>
            </div> -->
            <!-- <multiselect v-model="filter.advertiser" style="font-size: 12px" select-label="Select"
              deselect-label="Can't Remove" placeholder="Recommended" :options="allClients" label="client_name"
              track-by="client_id" :searchable="true" :allow-empty="true" :multiple="true" class="filter_multiselect">
            </multiselect> -->
          <!-- </div> -->
          
          <!-- <div class="col-12">
            <hr>
            </div> -->
            <div class="mt-3 col-12">
            <label style="color:#9C9C9C;font-size: 12px;margin:0px" for="">Date</label>
            <div @click="modal_content='calendar'" class="d-flex justify-content-between  cursor-pointer" style="color:#0F0090;width:98%;font-weight: 600;font-size: 14px;">
              <div>
                  {{ moment(dates[0]).format('MMMM Do YYYY') }} - {{  moment(dates[1]).format('MMMM Do YYYY ') }}
              </div>
              <div>
                 <img class="" src="../../../../public/media/svg/moon-assets/keyboard_arrow_right.svg" />
              </div>
            </div>
          </div>
          <!-- <div class="col-12">Date</div>
          <div class="col-5 d-flex">
            <div class="filter-calender">
              <v-menu ref="menu2" :content-class="isMobile ? '' : 'cal-dropdown'" rounded="large" v-model="menu2"
                :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dates[0]" readonly :placeholder="dates[0] ? dates[0] : 'From'" v-bind="attrs"
                    v-on="on">
                    <template v-slot:prepend>
                      <img src="/media/svg/moon-assets/calender.svg" style="
                          height: 13px;
                          width: 13px;
                          margin-right: 8px;
                          margin-top: 4px;
                        " />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="dates[0]" :disabled="noDateSpecified" @input="menu2 = false" no-title
                  :input-props="{ class: 'data-picker' }"></v-date-picker>
              </v-menu>
            </div>
          </div>
          <div class="col-2 text-center mt-3"><b>to</b></div>
          <div class="col-5 d-flex">
            <div class="filter-calender">
              <v-menu ref="menu3" :content-class="isMobile ? '' : 'cal-dropdown'" rounded="large" v-model="menu3"
                :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dates[1]" readonly :placeholder="dates[1] ? dates[1] : 'To'" v-bind="attrs"
                    v-on="on">
                    <template v-slot:prepend>
                      <img src="/media/svg/moon-assets/calender.svg" style="
                          height: 13px;
                          width: 13px;
                          margin-right: 8px;
                          margin-top: 4px;
                        " />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="dates[1]" @input="menu3 = false" no-title :min="dates[0]"
                  :disabled="noDateSpecified || !dates.length || !dates[0]"
                  :input-props="{ class: 'data-picker' }"></v-date-picker>
              </v-menu>
            </div>
          </div> -->

          <!-- <div class="col-12">
            <b-form-checkbox id="checkbox-1" v-model="noDateSpecified" name="checkbox-1" :value="true"
              :unchecked-value="false" size="lg">
              <label style="font-size: 13px">No Date Specified</label>
            </b-form-checkbox>
          </div> -->
          <div class="mt-3 col-12">
            <label style="color:#9C9C9C;font-size: 12px;margin:0px"  for="">Coupon</label>
            <!-- <input type="text" class="form-control theme-input"> -->
            <multiselect v-model="filter.coupon" style="font-size: 12px" select-label="Select"
              deselect-label="Can't Remove" placeholder="Select Coupon" :options="allCoupons" label="coupon"
              track-by="_id" :searchable="true" :allow-empty="true" :multiple="true" class="filter_multiselect">
            </multiselect>
          </div>
          <div class="mt-3 col-12">
            <label style="color:#9C9C9C;font-size: 12px;margin:0px" for="">Advertiser</label>

            <multiselect v-model="filter.advertiser" style="font-size: 12px" select-label="Select"
              deselect-label="Can't Remove" placeholder="Select Advertiser" :options="allClients" label="client_name"
              track-by="client_id" :searchable="true" :allow-empty="true" :multiple="true" class="filter_multiselect">
            </multiselect>
          </div>

          <!-- Currencies filter -->
          <!-- <div class="mt-3 col-12">
            <label for="">Currency</label>
            <multiselect
              v-model="filter.selectedCurrency"
              style="font-size: 12px"
              select-label="Select"
              placeholder="Select currency"
              :options="currencies"
              label="key"
              track-by="value"
              :searchable="true"
              class="filter_multiselect"
            ></multiselect>
          </div> -->
        </div>
        <div v-if="modal_content == 'recommended'">
          <div class="d-flex justify-content-between mt-3" style="color:#0F0090;font-weight: 600;">
            <div>
              Recommended
            </div>
            <b-form-checkbox  size="lg">
            </b-form-checkbox>
          </div>
          <div class="d-flex justify-content-between mt-3" style="color:#0F0090;font-weight: 600;">
            <div>
              Top Rated
            </div>
            <b-form-checkbox  size="lg">
            </b-form-checkbox>
          </div>
          <div class="d-flex justify-content-between mt-3" style="color:#0F0090;font-weight: 600;">
            <div>
              A-Z
            </div>
            <b-form-checkbox  size="lg">
            </b-form-checkbox>
          </div>
        </div>
        <div v-if="modal_content == 'calendar'">
          
        <date-range-picker @changedates="changedates" :dates="dates"/>
        </div>
      </template>

      <template #modal-footer="{}">
        <div class="d-flex justify-content-center w-100">
        <b-button class="modal-btn" variant="dark" @click="apply_filter()">
          APPLY
        </b-button>
      </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '../../../core/services/api.service';
import DatePicker from 'vue2-datepicker';
import VueFlatpickr from 'vue-flatpickr-component';
import moment from 'moment';
import 'flatpickr/dist/flatpickr.css';
import dateRangePicker from './date-range-picker.vue';
// import 'vue2-datepicker/index.css';
import Multiselect from 'vue-multiselect';
import Loader from '@/view/content/Loader.vue';
import { mapGetters } from 'vuex';
import CardContent from '../Communicators/CardContent.vue';
import MobileSideNav from '../Communicators/MobileSideNav.vue';
import { LOGOUT } from '@/core/services/store/auth.module';
import Vue from 'vue';

export default {
  name: 'dashboard',
  components: {
    Multiselect,
    DatePicker,
    flatPickr: VueFlatpickr,
    dateRangePicker,
    ApiService,
    Loader,
    CardContent,
    MobileSideNav,
  },

  data() {
    return {
      // moon_status: '',
      widgets: {
        new_advertiser: 0,
        total_advertiser: 0,
        total_conversion: 0,
        total_payout: 0,
        total_payout_currency: 'AED',
      },

      toggletype: 'table',
      moment,
      modal_content:'main',
      visible: false,
      menu: false,
      menu2: false,
      menu3: false,
      date: [],
      active: false,
      dates: [],
      pickerPage1: null,
      pickerPage2: null,
      events: [],
      eventColor: {},
      tempDates:'',
      lang: 'en',
      show: false,
      dashboard: {
        last_refreshed: new Date(),
        total_payout: 0,
        total_conversions: 0,
        total_payout_currency: 'AED',
      },
      last_refreshed: localStorage.getItem('lastSeen')
        ? new Date(localStorage.getItem('lastSeen'))
        : new Date(),
        selectedDates:[],
      selected_tab: 'summary',
      all_data: [],
      sum_fields: [
        {
          label: 'Brand Name',
          key: 'client_name',
          sortable: true,
          class: 'text-center',
        },
        {
          label: 'Coupon',
          key: 'coupon',
          class: 'text-center',
          sortable: true,
        },
        {
          label: 'Conversions',
          key: 'conversions',
          sortable: true,
          class: 'text-center',
        },
        {
          label: 'Payout',
          key: 'payout',
          sortable: true,
          class: 'text-center',
        },
        {
          label: 'Revenue',
          key: 'revenue',
          sortable: true,
          class: 'text-center',
        },
      ],
      sum_arr: [],
      order_columns: [
        {
          label: 'Brand Name',
          field: 'client_name',
          headerClass: 'add ',
          sortable: false,
        },
        {
          label: 'Coupon',
          field: 'coupon',
          headerClass: 'coupon',
          sortable: false,
        },
        {
          label: 'Order Date',
          field: 'order_date',
          headerClass: 'order_date',
        },
        {
          label: 'Payout',
          field: 'payout',
          headerClass: 'payout text-center',
        },
        {
          label: 'Revenue',
          field: 'revenue',
          headerClass: 'payout text-center',
        },
      ],
      currencies: [],
      order_arr: [],
      filter: {
        advertiser: [],
        coupon: [],
        start_date: null,
        end_date: null,
        date_type: 'Daterange',
        selectedCurrency: 'AED',
      },
      adver_array: [],
      coupon_array: [],
      allClients: [],
      allCoupons: [],
      orderPagination: null,
      loading: false,
      isMobile: false,
      username: '',
      userInfo: window.localStorage.getItem('userinfo')
        ? JSON.parse(window.localStorage.getItem('userinfo'))
        : {},
      reportMessage: '',
      noDateSpecified: false,
    };
  },
  watch: {
    // "filter.start_date"() {
    //   this.filter.end_date = null;
    // },
    // dates() {
    //   if (this.disabledRange()) return;
    //   this.get_widgets();
    // },
    noDateSpecified() {
      if (this.noDateSpecified) {
        this.dates = [];
      }
    },
    menu() {
      if (!this.menu && this.dates[0] && !this.dates[1]) {
        const temp = this.dates[0];
        this.dates = [];
        this.dates[0] = temp;
        this.date[0] = temp;
        this.dates[1] = temp;
        this.date[1] = temp;
        this.$forceUpdate();
        this.apply_filter();
      }
    },
    'dates[0]'() {
      this.dates[1] = null;
      this.date[1] = null;
    },
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true });
    }
  },
  created() {
    window.addEventListener('click', (e) => {
      if (e.target && e.target.id && e.target.id === 'filter_modal') {
        this.show = false;
        this.modal_content = 'main'
      }
    });

    setInterval(() => {
      if (!this.isAuthenticated) return;
      this.checkSessionLimit();
    }, 1000 * 60 * 60);
  },
  beforeMount() {
    window.addEventListener('beforeunload', (e) => {
      this.sessionTrack();
    });

    // Broadcast that you're opening a page.
    // localStorage.openpages = Date.now();
    // window.addEventListener('storage', function (e) {
    //     if(e.key == "openpages") {
    //         // Listen if anybody else is opening the same page!
    //         localStorage.page_available = Date.now();
    //     }
    //     if(e.key == "page_available") {
    //        localStorage.setItem('already_open',true)
    //     }
    // }, false);

    // if user close the tab without logout then again open the tab so start session again
    if (!localStorage.getItem('session-start')) {
      localStorage.setItem(
        'session-start',
        `${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}`
      );
    }
  },
  computed: {
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
    ...mapGetters(['layoutConfig', 'isAuthenticated']),
    valid() {
      return this.dates.length === 2;
    },
    datesHeader() {
      let date1 = new Date(this.dates[0]);
      let date2 = new Date(this.dates[1]);
      if (date1 > date2) {
        let temp = this.dates[0];
        this.dates[0] = this.dates[1];
        this.dates[1] = temp;
      }
      return this.dates.join(' → ');
    },
  },
  mounted() {
    if (!this.isAuthenticated || !localStorage.getItem('is_login')) {
      localStorage.clear();
      this.$router.push({ name: 'login' });
    }
    // Vue.axios.defaults.baseURL = "https://stag.api.ims.directory/api/v1/";
    /* by default current month dates selected */
    this.setDefaultMonthDate();
    this.username = window.localStorage.getItem('user');
    this.fetchData();
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true });
    //this.get_moon_status();
  },

  methods: {
    changedates(e){
    console.log(e,'enter')
    this.tempDates = e;
    },
    OneDayAgo(date) {
      const day = 1000 * 60 * 60 * 24;
      const dayago = Date.now() - day;
      return date < dayago;
    },
    async checkSessionLimit() {
      let start_time = localStorage.getItem('session-start')
        ? new Date(localStorage.getItem('session-start')).getTime()
        : null;
      const isOneDayAgo = this.OneDayAgo(start_time);
      if (isOneDayAgo) {
        await this.sessionTrack();
        this.logout();
      }
    },
    async sessionTrack() {
      ApiService.post('moon/track-time', {
        start_time: localStorage.getItem('session-start')
          ? new Date(localStorage.getItem('session-start'))
          : new Date(),
        end_time: new Date(),
        page_user_id: this.userInfo ? this.userInfo._id : null,
        page_url: window.location.href,
      });

      window.localStorage.removeItem('session-start');
      window.localStorage.removeItem('session-end');
    },
    setDefaultMonthDate() {
      let date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      const firstDay = new Date(y, m, 1);
      const lastDay = new Date(y, m + 1, 0);
      this.dates = [];
      this.dates[0] = firstDay
        .toLocaleString('sv', { timeZoneName: 'short' })
        .substr(0, 10);
      this.date[0] = this.dates[0];
      this.dates[1] = lastDay
        .toLocaleString('sv', { timeZoneName: 'short' })
        .substr(0, 10);
      this.date[1] = this.dates[1];
    },
    disabledRange: function (date) {
      return !this.dates[0] || this.dates[1] < this.dates[0];
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
      if (this.isMobile) {
        this.toggletype == 'grid';
      }
    },
    shown() {
      this.show = true;
    },
    hidden(e) {
      this.show = false;
    },

    /* get total payout conversions */
    create_csv(arrData, col) {
      let csvContent = 'data:text/csv;charset=utf-8,';
      let get_col = [];
      col.forEach((element) => {
        get_col.push(element.label);
      });
      get_col = get_col.join(',');

      csvContent += [
        get_col,
        ...arrData.map((item) => Object.values(item).join(',')),
      ]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '');

      const data = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute('download', 'performance_overview.csv');
      link.click();
    },
    /* get all clients to filter order or summary */
    get_all_clients() {
      ApiService.get('/conversion/clients').then((response) => {
        this.allClients = [...new Set(response.data.data)];
      });
    },

    /* get all currency conversions */
    get_currencies() {
      ApiService.get('/conversion/currency').then((response) => {
        const curr = response.data.data;
        for (const property in curr) {
          this.currencies.push({
            key: property,
            value: curr[property],
          });
        }

        this.filter.selectedCurrency = this.currencies.find(
          (x) => x.key == 'AED'
        ); // default currency => AED
      });
    },

    /* get all coupons to filter order or summary */
    get_all_coupons(payload) {
      ApiService.post('/conversion/coupons', {}).then((response) => {
        this.allCoupons = [...new Set(response.data.data)];
      });
    },

    /* get conversion summary */
    async get_conversion_summary(payload) {
      let response = await ApiService.post('/conversion/summary', payload);
      this.sum_arr = response.data.data.map((item) => {
        return {
          client_name: item.client_name,
          coupon: item.coupon,
          conversions: item.conversions,
          payout_currency: this.filter.selectedCurrency
            ? this.filter.selectedCurrency.key
            : item.payout_currency,
          payout:
            item.payout *
            (this.filter.selectedCurrency
              ? this.filter.selectedCurrency.value
              : 1),
          revenue_currency: this.filter.selectedCurrency
            ? this.filter.selectedCurrency.key
            : item.revenue_currency,
          revenue:
            item.revenue *
            (this.filter.selectedCurrency
              ? this.filter.selectedCurrency.value
              : 1),
        };
      });
    },
    // get widgets
    async get_widgets() {
      let response = await ApiService.post(
        'conversion/widget',

        {
          moon_id: this.userInfo.influencer_id
            ? this.userInfo.influencer_id
            : '61a09a1cb6b04e0743683dd7',

          start_date:
            !this.noDateSpecified && this.dates.length > 0
              ? this.dates[0]
              : null,
          end_date:
            !this.noDateSpecified && this.dates.length > 1
              ? this.dates[1]
              : null,
          client_ids: this.filter.advertiser.map((x) => x.client_id),
          coupon_ids: this.filter.coupon.map((x) => x._id),
        }
      );
      this.widgets = response.data.data;

      (this.widgets.new_advertiser = this.widgets.new_advertiser.toLocaleString(
        undefined,
        {
          maximumFractionDigits: 2,
        }
      )),
        (this.widgets.total_advertiser = this.widgets.total_advertiser.toLocaleString(
          undefined,
          {
            maximumFractionDigits: 2,
          }
        )),
        (this.widgets.total_conversion = this.widgets.total_conversion.toLocaleString(
          undefined,
          {
            maximumFractionDigits: 2,
          }
        ));

      // payout currency conversion
      this.widgets.total_payout =
        this.widgets.total_payout *
        (this.filter.selectedCurrency ? this.filter.selectedCurrency.value : 1);
      this.widgets.total_payout = this.widgets.total_payout.toLocaleString(
        undefined,
        {
          maximumFractionDigits: 0,
        }
      );

      this.widgets.total_payout_currency = this.filter.selectedCurrency
        ? this.filter.selectedCurrency.key
        : this.widgets.total_payout_currency;
    },

    /* fetch data method to call all apis to get data. */
    async fetchData() {
      if (!localStorage.getItem('lastSeen')) {
        localStorage.setItem('lastSeen', new Date());
      }
      console.log('fetch data');
      this.get_all_clients();
      this.get_all_coupons();
      let payload = {
        start_date:
          !this.noDateSpecified && this.dates.length > 0 ? this.dates[0] : null,
        end_date:
          !this.noDateSpecified && this.dates.length > 1 ? this.dates[1] : null,
        client_ids: this.filter.advertiser.map((x) => x.client_id),
        coupon_ids: this.filter.coupon.map((x) => x._id),
      };
      this.loading = true;
      await Promise.all([
        await this.get_currencies(),
        await this.get_widgets(),
        await this.get_conversion_summary(payload),
      ]);
      this.loading = false;
    },

    /* refreshed method to reset all filters and get all data from server */
    refreshed_data() {
      this.filter.date_type = 'Daterange';
      this.filter.start_date = null;
      this.filter.end_date = null;
      this.filter.advertiser = [];
      this.filter.coupon = [];
      this.fetchData();
      this.last_refreshed = new Date();
      localStorage.setItem('lastSeen', new Date());
    },

    /* set filter from filter modal and hit api again */
    async apply_filter() {
      if(this.modal_content =='main'){
      let payload = {
        start_date:
          !this.noDateSpecified && this.dates.length > 0 ? this.dates[0] : null,
        end_date:
          !this.noDateSpecified && this.dates.length > 1 ? this.dates[1] : null,
        client_ids: this.filter.advertiser.map((x) => x.client_id),
        coupon_ids: this.filter.coupon.map((x) => x._id),
      };
      this.loading = true;
      this.show = false;
      await Promise.all([
        await this.get_widgets(),
        await this.get_conversion_summary(payload),
      ]);
      this.loading = false;
      this.$bvModal.hide('filter_modal');
    }else if(this.modal_content == 'recommended'){

    }else if(this.modal_content == 'calendar'){
      if(this.tempDates.split('to').length <= 1){
        // alert('Please Select Date in Range')

        this.dates = this.tempDates.split('to')
        this.dates[0] = this.dates[0].trim()
        this.dates[1] = this.dates[0].trim()
        this.modal_content = 'main'
      }else{
        this.dates = this.tempDates.split('to')
        this.dates[0] = this.dates[0].trim()
        this.dates[1] = this.dates[1].trim()
        this.modal_content = 'main'
      }
    }
    },
    async clear_filter() {
      this.emptyFilterData();
      this.loading = true;
      // this.show = false;
      await Promise.all([
        await this.get_widgets(),
        await this.get_conversion_summary({}),
      ]);
      this.loading = false;
      // this.$bvModal.hide('filter_modal');
    },
    emptyFilterData() {
      this.filter.advertiser = [];
      this.filter.coupon = [];
      this.dates = [];
      this.noDateSpecified = false;
    },

    /* util function to get date difference in minute, hours or seconds */
    last_refreshed_diff(diffMs) {
      diffMs = Math.abs(new Date() - diffMs);
      var diffDays = Math.abs(Math.floor(diffMs / 86400000)); // days

      var diffHrs = Math.abs(Math.floor((diffMs % 86400000) / 3600000)); // hours
      var diffMins = Math.abs(
        Math.round(((diffMs % 86400000) % 3600000) / 60000)
      ); // minutes
      let status = diffDays ? diffDays + ' d ' : '';
      status += diffHrs ? diffHrs + ' h ' : '';
      status += diffMins ? diffMins + ' m ' : '';
      // return(diffDays + " days, " + diffHrs + " hours, " + diffMins );
      return status ? `${status} ago` : 'just now';
    },
    getMondayForWeek(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff - 7));
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    formatDate(d) {
      return dateFormat(d, 'yyyy-mm-dd');
    },
    formatTime(d) {
      return dateFormat(d, 'isoTime');
    },
    formatDateTime(d) {
      let date = this.formatDate(d);
      let time = this.formatTime(d);
      // return dateFormat(d, "isoDateTime");
      return date + ' ' + time;
    },
    formatCurrency(value) {
      return value.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      });
    },
    logout() {
      this.$store.dispatch(LOGOUT).then(() => {
        localStorage.setItem(
          'session-end',
          `${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}`
        );
        this.sessionTrack();
        this.$router.push({ name: 'login' });
      });
    },
    async sendReport() {
      try {
        await ApiService.post('/conversion/report-problem', {
          message: this.reportMessage,
        });
        this.reportMessage = '';
      } catch (err) {
        console.log(err);
      } finally {
        this.$bvModal.hide('report-modal');
      }
    },
    openCalender() {
      document.getElementById('input-11').click();
    },
  },
};
</script>

<style lang="scss">
// .searchBar{
//   padding: 10px 10px;
//   background-color: #E6E8F0;
//     border: 1px solid #fff;
//   border-radius: 10px;
// }
// .searchBar:focus{
//     border: 1px solid #fff;
// }
.closeIcon{
  font-size:15px;
  text-align: center;
  display: flex;
  border-radius:5px;
  padding: 3px 3px;
  background-color: #0F0090;
  color:white;
}
.gridTable thead tr th{
color:#0F0090 !important;
}
.gridTable tbody tr td{
color:#0F0090 !important;
}
.gridOptions {
  cursor: pointer;
  width: 135px;
  top: 20px;
  height: fit-content;
  display: flex;
  flex-flow: column;
  position: absolute;
  z-index: 200;
  font-size: 12px;
  right: 20px;
  background-color: white;
  border-radius: 10px;
  text-align: start;
  box-shadow: -4px 9px 33px #8888886b;
}

.gridOption {
  padding: 8px 10px;
}

.week {
  color: #9C9C9C;
}

.cardIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
}

.BellCount {
  position: absolute;
  border-radius: 50%;
  top: 0;
  text-align: center;
  right: 0;
  width: 20px;
  color: white;
  height: 20px;
  background-color: #FF754C;
}

.BellDiv {
  margin-right: 10px;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0px 15px 65px #888888;
  background-color: #fff;
}

.searchIcon {
  border-right: none;
  padding: 9px;
  margin-top: 0px;
  margin-left: 10px;
  width: 50px;
  border-radius: 10px 0px 0px 10px;
  height: 40px;
  background-color: #E6E8F0;
  outline: none;
  color: #9DBFAF;
}

.searchDiv {
  position: relative;
  // display: flex;
  display: none;
}

.searchTerm {
  border-right: none;
  height: 40px;
  padding: 10px;
  border-radius: 0px 10px 10px 0px;
  background-color: #E6E8F0;
  outline: none;
  color: #9DBFAF;
}

.searchTerm:focus {
  color: #070101;
  border: none
}


.b-table-sticky-header {
  overflow-y: visible;
  max-height: 100%;
}

.bar-text {
  top: 0;
  color: #fff;
  background: #324772;
  width: 100%;
  padding: 0.5rem;
}

.table thead th {
  vertical-align: top;
  padding: 1.75rem;
  color: #7f8fa4;
  background-color: #f7f8fa;
}

.b-table-sticky-header>.table.b-table>thead>tr>th {
  background-color: #f7f8fa;
  color: #7f8fa4;
}

.table td {
  padding: 1.75rem;
  vertical-align: top;
  border-top: 10px solid #f7f8fa;
  background: #fff;
}

.badge {
  color: white;
  border-radius: 5px;
}

// .content {
//   margin-bottom: 175px;
// }
.content2 {
  margin-top: 25px;
}

.new-tag-bg {
  background: #0253b0;
  border-color: #0253b0;
}

.comfirmed-tag-bg,
.approved-tag-bg {
  background: #00c778;
  border-color: #00c778;
}

.cancelled-tag-bg {
  background: #707070;
  border-color: #707070;
}

.export_file {
  float: right;
  cursor: pointer;
}

.s_date {
  // margin-right: 4%;
  cursor: pointer;
}

.select {
  color: #000;
  font-weight: 700;
}

.data_grid {
  max-height: 70vh;
  margin-bottom: 40px;
  overflow: auto;
}

.modal-btn {
  font-size: 14px;
  height: 40px;
  width: 90%;
  float: left;
  color: white;
  background: #0F0090 !important;
  border: none;
  border-radius: 20px;
}

.tabs {
  display: flex;
  text-align: center;
  font-weight: 600;
  border-bottom: 1px solid #0e0c0c;

  .tab {
    cursor: pointer;
  }

  .active {
    border-bottom: 1.5px solid black;
  }
}

.refresh-btn {
  background: #5ebb46 0% 0% no-repeat padding-box;
  color: #fff;
  border-radius: 5px;
  border: none;
  font-weight: 700;
  width: 15px !important;
}

// for destop
@media only screen and (min-width: 1200px) {
  .plan-boxes {
    background-color: transparent;

    span:first-of-type {
      // margin-bottom: 7%;
    }

    .card-body {
      height: 190px;
    }
  }

  .plan-box {
    background-color: #ffffff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    span:first-of-type {
      display: block;
      text-transform: uppercase;
      color: #000;
      font-size: 10px;
    }

    h1 {
      font-size: 41px/49px;
      font-weight: 900;
      font-family: lato;
      //font-weight: bold;
      color: #000;
      //font-size: 4rem;
      margin: 10px 0;
    }

    span:last-of-type {
      display: block;
      text-transform: capitalize;
      font-size: 13px;
      color: #354052;
    }
  }

  .modal-content {
    // width: 35%;
    // height: 100vh;
    // position: fixed;
    // right: -10px;
    // top: 0;
  }
}
.flatpickr-rContainer .flatpickr-days .dayContainer .selected{
  background-color: #0F0090 !important;
}
.flatpickr-rContainer .flatpickr-days .dayContainer .inRange{
  background-color: #fbe2f5 !important;
  color:#0F0090 !important;
}
.flatpickr-rContainer .flatpickr-days .dayContainer .flatpickr-day:hover{
  background-color: #0F0090 !important;
  color: #fff !important;
}
.flatpickr-day{
  color:#827AC5;
}
.flatpickr-current-month{
  color: #0F0090 !important;
}
.flatpickr-weekdays{
  border-bottom: 1px solid #DBD9EE !important;
}
.flatpickr-weekday{
  color: #0F0090 !important;
}
.modal-content{
  width:fit-content;
  border-radius: 20px;
}
.last_seen {
  color: white;
  text-align: left;
  float: left;
  margin-top: 5px;
}
.modal-body{
  min-width: 9cm;
  max-width: 15cm;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #357a89;
  color: white;
  text-align: center;
  font: normal normal normal 14px/30px Poppins;
  height: 30px;
}

// for mobile
@media only screen and (max-width: 1200px) {
  .plan-boxes {
    background-color: transparent;
    // margin-bottom: 5%;
    display: inline;

    span:last-of-type {
      // float: right;
    }

    .card-body {
      height: 190px;
    }
  }

  .plan-box {
    background-color: #ffffff;
    width: 100%;
    // min-height: 100%;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    span:first-of-type {
      display: block;
      text-transform: uppercase;
      color: #000;
      // font-weight: bold;
      font-size: 10px;
    }

    h1 {
      //font: normal normal 900 41px/49px Lato;
      font-size: 41px/49px;
      font-weight: 900;
      font-family: lato;
      color: #000;
      font-size: 2rem;
      margin: 10px 0;
    }

    span:last-of-type {
      display: block;
      text-transform: capitalize;
      color: #000;
      // font-weight: bold;
      font-size: 12px;
    }
  }

  .summary-table {
    margin-top: 5%;
    margin-bottom: 60px;
    background: #f7f8fa !important;
  }

  .nav-calender {
    margin-left: auto;
    width: 158px !important;
  }
}

.ml-10p {
  margin-left: 10%;
}

.card-1 {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 3px;
}

// new css

.group-regular {
  height: 60px;
  width: 60px;
  opacity: 1;
}

.card-body {
  top: 459px;
  left: 114px;
  // width: 235px;
  width: 100%;
  // height: 220px;
  background: white 0% 0% no-repeat padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  opacity: 1;
}

.card {
  border: none;
  background-color: transparent;
  // padding-top: 10px;
}

.bg-info {
  background-color: #357a89 !important;
}

.top {
  top: 25px !important;
}

.custom-switch.b-custom-control-lg .custom-control-label::before,
.input-group-lg .custom-switch .custom-control-label::before {
  // top: 0.3125rem;
  height: 2.75rem !important;
  // left: -2.8125rem;
  width: 11.1875rem !important;
  border-radius: 0.625rem;
  background: #35476f 0% 0% no-repeat padding-box !important;
  content: 'Rewards';
  color: white;
  padding-left: 58px;
  padding-top: 8px;
  font-size: 12px;
}

.custom-switch.b-custom-control-lg .custom-control-label::after,
.input-group-lg .custom-switch .custom-control-label::after {
  top: calc(0.3125rem + 2px);
  left: calc(-2.8125rem + 2px);
  width: calc(5.65rem - 4px) !important;
  height: calc(2.75rem - 4px) !important;
  border-radius: 0.625rem;
  background-size: 50% 50%;
  content: 'Badges';
  background-color: white;
  font-size: 12px;
  padding-top: 6px;
}

.custom-control-input:checked~.custom-control-label::before {
  background: #35476f 0% 0% no-repeat padding-box !important;
}

.custom-switch.b-custom-control-lg,
.input-group-lg .custom-switch {
  padding-right: 7.8125rem !important;
}

.custom-switch.b-custom-control-lg .custom-control-input:checked~.custom-control-label::after,
.input-group-lg .custom-switch .custom-control-input:checked~.custom-control-label::after {
  transform: translateX(5.5375rem);
  content: 'Rewards';
  font-size: 12px;
  padding-top: 6px;
}

.font-family {
  font-family: 'Lato' !important;
  background: #f7f8fa 0% 0% no-repeat padding-box;
}

.side-nav {
  background-color: white;
  padding: 12px !important;
}

.reminder-content {
  font-family: 'Lato';
  font-size: 14px;
  font-weight: bold;
  text-align: left;
}

.icon-crown {
  background: #ffa101 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  width: 60px;
  height: 60px;
}

.nav-bar {
  background: #357a89 !important;
  box-shadow: 0px -4px 18px #0000000f;
  opacity: 1;
}

.btn.btn-light {
  color: #35717e;
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  height: 30px;
  font-size: 10px;
  font-weight: 700;
}

.nav-calender {
  margin-left: auto;
  width: 271px;
  height: 35px;
  text-align: center;
  background-color: #35717e;
  border-radius: 20px;
}

// Added styled scrollbar to all areas that will scroll throughout the app.
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #0e0090c8;
  background-clip: content-box;
}

.data-btn {
  text-align: left;
  background: 0% 0% no-repeat padding-box padding-box rgb(243, 81, 98);
  border-radius: 12px;
  opacity: 1;
  width: 100%;
  color: rgb(255, 255, 255);
  border: none;
  height: 40px;
  padding: 0px 0px 0px 15px;
}

.report-btn {
  background: 0% 0% no-repeat padding-box padding-box rgb(53, 122, 137);
  border-radius: 12px;
  border: none;
  color: rgb(255, 255, 255);
  width: 100%;
  height: 30px;
  font-size: 12px;
}

.logout-btn {
  color: #35717e;
  background-color: #f3f6f9;
  height: 30px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 0px !important;
  margin-left: 6px !important;
  border-radius: 4px !important;
  padding: 6px !important;
}

.v-date-picker-table .v-btn.v-btn--active {
  background-color: blue !important;
}

.v-text-field {
  padding-top: 0px !important;
  margin-top: 0px !important;
  border: none;
  color: #fff !important;
}

.theme--light.v-input input,
.theme--light.v-input textarea,
.v-icon,
.theme--light.v-input input::placeholder {
  color: #fff !important;
  font-size: 13px !important;
}

.theme--light.v-text-field>.v-input__control>.v-input__slot:before {
  border: none !important;
}

.row+.row {
  margin-top: 0px !important;
}

.navbar-brand {
  width: 67%;
}

.v-date-picker-table .v-btn.v-btn--active {
  background-color: #0F0090 !important;
}

.theme--light.v-btn.v-btn--icon {
  background-color: #0F0090 !important;
}

.v-input__prepend-outer {
  margin: 1px !important;
}

.v-input__slot {
  margin: -4px !important;
}

.cal-dropdown {
  margin-left: -52px !important;
}

.theme-input,
.theme-input:disabled,
.theme-input:read-only,
.multiselect__input,
.multiselect__tags,
.multiselect__single {
  background-color: #fff !important;
  border-color: #fff !important;
  color: #0F0090 !important;
  border-radius: 5px;
  font-size: 16px;
}

.filter-calender {
  height: 35px;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  font-size: 16px;
  background-color: #0F0090;
}
.multiselect__option--highlight, .multiselect__option--highlight:after{
  background: #0F0090 !important;
}

.cancel-filter {
  background: white !important;
  color: #357a89 !important;
  border: 1px solid #357a89 !important;
  height: 38px !important;
  line-height: 19px !important;
}

.filter_multiselect .multiselect__placeholder {
  color: #0F0090 !important;
  font-weight: 600;
}

// .multiselect * {
//   color: black !important;
// }

.filter_multiselect .multiselect__tag {
  background: #0F0090 !important;
}

.filter_multiselect .multiselect__select:before {
  border-color: #0F0090 transparent transparent;
}

.filter_multiselect .multiselect__tag-icon:after {
  vertical-align: bottom !important;
  color:white;
}
.filter_multiselect .multiselect__tag-icon:hover {
  background-color: #0F0090
}

.multiselect__input::placeholder,
.multiselect__single {
  color: #0F0090 !important;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-datepicker/index.css"></style>
