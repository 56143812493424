<template>
    <div class="custom-date-range">
      <div>
    <input ref="flatpickrInput" v-model="componentsDates" @input="handelClickDates($event)" class="flatpickerInput"/>
        <!-- <flat-pickr
          v-model="selectedDate"
          :config="calendarConfig"
          @input="dateSelected"
        ></flat-pickr> -->
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        startDate: '',
        endDate: '',
        showCalendarStart: false,
        componentsDates:'',
        showCalendarEnd: false,
        selectedDate: null,
        calendarConfig: {
          mode: 'range'
          // Add more configuration options as needed
        }
      };
    },
    props:['dates'],
    mounted() {
    // Initialize Flatpickr when the component is mounted
    flatpickr(this.$refs.flatpickrInput, {
      // Options can be configured here
      // For example:
      dateFormat: 'Y-m-d', // Customize the date format
        mode:'range',
        inline: true
      // You can add more options according to your requirements
    });
  },
    methods: {
        handelClickDates(){
            this.$emit('changedates',this.componentsDates);
        }
    }
  };
  </script>
  
  <style scoped>
  .flatpickerInput{
    display: none;
  }
  .custom-date-range-picker {
    display: inline-block;
    font-family: Arial, sans-serif;
  }
  
  .date-inputs {
    display: flex;
    align-items: center;
  }
  
  .date-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 5px;
  }
  
  .date-separator {
    margin: 0 5px;
  }
  
  .calendar-container {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 1000;
  }
  </style>