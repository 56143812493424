<template>
  <div>
    <!-- <b-card class="badge-section">
      <b-row class="badge-content">
        <b-col cols="12"> 
          <div style="display: flex;justify-content:center">
          <img class="" src="../../../../public/media/svg/moon-assets/crown.svg"  />
          <h2 class="pl-2" style="font: normal normal 501 20px/24px Raleway;color: #0F0090;">
            {{ moon_status }}{{' Badge'}}
          </h2>
        </div>
        </b-col>
        <b-col cols="12"></b-col>
        <b-col cols="12" class="pt-2">
          <button
            style="font-size: 14px;border-radius: 35px;"
            class="btn btn-dark form-control"
          >
          Lorem Ipsum
          </button>
          -- <p style="font: normal normal normal 12px/14px Lato">
            For more Profile Control
          </p> --
        </b-col>
      </b-row>
    </b-card> -->
    <!-- <p class="h1">
      <b-icon icon="three-dots" variant="primary" size="lg"></b-icon>
    </p> -->
    <!-- reminder -->
    <!-- <b-card class="milestone">
      <h5 class="reminder-content">Milestones</h5>
      <div class="overflow-card">
        <b-row v-for="(item, index) in milestones" :key="index">
          <b-col class="pr-0 pt-0" cols="2">
            <b-icon
              :style="
                item.status
                  ? 'color: #357a89 !important;'
                  : 'color: #D3CDCD !important;'
              "
              icon="check-circle"
              variant="info"
            ></b-icon>
          </b-col>
          <b-col class="pl-0 pt-0" cols="6">
            <p :class="item.status ? 'active-miletones' : 'inactive-miletones'">
              {{ item.message }}
            </p>
          </b-col>
          <b-col cols="4"> </b-col>
        </b-row>
      </div>
    </b-card> -->

    <!-- <b-row class="pt-4 mb-3">
      <b-col class="reminder-content" cols="3"> <span> Reminders</span></b-col>
      <b-col cols="6"></b-col>
      <b-col cols="3">
        <span style="color: #357a89; cursor: pointer">View All</span></b-col
      >
    </b-row> -->
  </div>
</template>

<script>
import ApiService from "../../../core/services/api.service";
import Vue from "vue";

export default {
  components: {
    ApiService,
  },
  data() {
    return {
      moon_status: "",
      milestones: [],
      userInfo: window.localStorage.getItem("userinfo")
        ? JSON.parse(window.localStorage.getItem("userinfo"))
        : {},
    };
  },
  mounted() {
    // Vue.axios.defaults.baseURL = "https://stag.api.ims.directory/api/v1/";
    this.get_moon_status();
  },
  methods: {
    async get_moon_status(payload) {
      let response = await ApiService.get(
        `influencers/${
          this.userInfo.influencer_id
            ? this.userInfo.influencer_id
            : "61a09a1cb6b04e0743683dd7"
        }`
      );
      // console.log("res", response);
      console.log("new moon_status", response.data.data.moon_status);
      console.log("new milestone", response.data.data);
      this.moon_status = response.data.data.moon_status;
      this.milestones = response.data.data.milestones;
      // this.status = response.data.data.milestones;
    },
  },
};
</script>

<style lang="scss" scoped>
.milestone {
  background: white 0% 0% no-repeat padding-box !important;
  height: 140px !important;
}
.active-miletones {
  color: #357a89;
  font: normal normal medium 16px/19px Lato;
  inline-size: max-content;
  text-align: left;
}
.inactive-miletones {
  color: #d3cdcd;
  font: normal normal medium 16px/19px Lato;
  inline-size: max-content;
  text-align: left;
}
.card-body {
  padding: 12px !important;
}
.reminder-content {
  margin-bottom: 8px !important;
}

.btn-dark {
  height: 44.1px;
  background: #fff !important;
  border-color: #fff !important;
  color: #0F0090 !important;
}
.btn-dark:hover {
  background: #fff !important;
  border-color: #fff    !important;
  color: #0F0090 !important;
}
.crown-img {
  width: 40px;
  height: 35px;
  text-align: center;
  margin-top: 10px;
}
.icon-crown {
  text-align: center;
}

.badge-section {
  margin-top: 30px;
  width: 100%;
  height: 140px;
  .card-body {
    background-color:#F7C2EA;
  }
  .badge-content {
    text-align: left;
    height: 111%;
    align-content: end;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-top: -20px;
  }
}
.overflow-card {
  height: 80%;
  padding-top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
