<template>
  <div class="grid-container">
    <b-row v-if="allClients.length">
      <b-col
        class="pb-3 col-12 col-md-4"
        v-for="(item, index) in allClients"
        :key="index"
      >
        <b-card class="plan-b">
          <b-row class="pb-2" style="align-items: center">
            <b-col cols="auto" class="pr-0"><b-avatar></b-avatar></b-col>
            <b-col class="value" cols="auto"
              ><b style="font-size: 12px">{{ item.client_name }}</b></b-col
            >
          </b-row>

          <b-row class="field">
            <b-col>
              <label class="mb-0"> Coupon </label><br />
              <span class="value">{{ item.coupon }}</span>
            </b-col></b-row
          >
          <br />

          <b-row class="field">
            <b-col class="col-6">
              <label class="mb-0"> Conversions </label><br />
              <span class="value">{{ item.conversions }}</span>
            </b-col>

            <b-col class="col-6">
              <label class="mb-0"> Payout </label><br />
              <span class="value">
                {{ item.payout_currency }} {{ item.payout.toFixed(2) }}
              </span>
            </b-col></b-row
          >
          <div class="mt-4">
            <b-row
              class=""
              style="
                border-top: 1px solid #dfdfdf !important;
                padding-top: 16px;
              "
            >
              <b-col
                class="field"
                style="color: #357a89; padding-top; 10px !important;"
              >
                Revenue
              </b-col>
              <b-col class="value" style="padding-top; 11px !important;"
                >{{ item.revenue_currency }} {{ item.revenue.toFixed(2) }}
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <div class="d-flex mt-3 justify-content-center alert">
          <b>No Records</b>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["allClients"],

  mounted() {},
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.plan-b {
  background-color: transparent;
  //margin-bottom: 5%;
  display: inline;

  .card-body {
    width: 100%;
    height: 220px;
    border-radius: 10px;
    padding: 16px !important;
  }
}
.value {
  font-size: 10px;
  color: #000;
  //font-weight: bold;
}
.field {
  //font: normal normal normal 15px/18px Lato;
  color: #7f8fa4;
}
.grid-container {
  margin-bottom: 60px;
}
</style>
